import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, validateInput, getValueInputRef } from '../../helpers'
import { Input, Card, Modal, Button, ModalAlert, Select, FormLoading, ExportExcel } from '../../components'
import { CommonService, UserService } from "../../services";
import DataGrid, { SearchPanel, Toolbar, Item, Paging, Pager, Column, HeaderFilter } from 'devextreme-react/data-grid';
import { PlusIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import { isEmpty } from 'lodash'

let firstRender = false
const UserGroup = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])
  const [loading, setLoading]: any = useState(false)
  const [loadingTable, setLoadingTable]: any = useState(false)
  const [dataSource, setDatasource]: any = useState([])
  const [validation, setValidation]: any = useState({})
  const [open, setOpen]: any = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm] = useState({
    "id": "",
    "group_name": "",
    "description": "",
    "group_status": "Y"
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)
  let columns = [
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center"
    },
    {
      dataField: 'group_name',
      caption: "กลุ่มผู้ใช้งาน",
      allowFiltering: true
    },
    {
      dataField: 'description',
      caption: "รายละเอียด"
    },
    {
      dataField: 'status_name',
      caption: "สถานะ",
      width: 100,
      allowFiltering: true
    },
    {
      dataField: 'updated_by',
      caption: "แก้ไขโดย"
    }
  ]
  let validateSchema: any = [
    {
      id: 'group_name',
      required: { value: true, message: "โปรดระบุ" }
    }
  ]

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getUserGroup()
      CommonService.getMasterData(auth, "common_status")
        .then((res: any) => {
          let options = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setOptionStatus(options)
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "id": "",
      "group_name": "",
      "description": "",
      "group_status": "Y"
    })
  }

  //#region Call services
  const getUserGroup = () => {
    setLoadingTable(true)
    UserService.getUserGroup(auth)
      .then((res: any) => {
        setDatasource(res?.result || [])
        setLoadingTable(false)
      })
      .catch((err) => {
        setDatasource([])
        setLoadingTable(false)
      })
  }

  const deleteUserGroup = (id) => {
    UserService.deleteUserGroup(auth, id)
      .then((res: any) => {
        if (res.code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
        }

      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)
    if (optionAlert.action.name === "create") {
      UserService.addUserGroup(auth, {
        group_name: formData.group_name,
        description: formData.description,
        group_status: dataForm.group_status
      })
        .then((res: any) => {
          let { code, message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            setOpen(false)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: message }
            })
          }
          setLoading(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }
    else {
      UserService.updateUserGroup(auth, {
        group_id: dataForm.id,
        group_name: formData.group_name,
        description: formData.description,
        group_status: dataForm.group_status
      })
        .then((res: any) => {
          setOpen(false)
          if (res.status === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
          }
          setLoading(false)
          initialData()
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }

  }
  //#endregion

  //#region 
  const onSave = () => {
    let validation = validateInput({
      schema: validateSchema,
      inputRef
    })

    if (isEmpty(validation)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validation)
    }
  }

  //#endregion

  return (
    <>
      <Card
        title="ข้อมูลกลุ่มผู้ใช้งาน"
        extra={<Button
          text="เพิ่มข้อมูล"
          icon={<PlusIcon />}
          iconPosition='start'
          onClick={() => setOpen(true)}
        />}
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loadingTable} tip="">
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              wordWrapEnabled={false}
              hoverStateEnabled={true}
            >
              <SearchPanel visible={true} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  allowFiltering={column.allowFiltering || false}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                />)
              }
              <Column
                fixed={true}
                caption="แก้ไข/ลบ"
                type="buttons"
                width={80}
                buttons={[
                  {
                    hint: "แก้ไข",
                    icon: "fa fa-pencil-square-o",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data
                      setDataForm({
                        "id": data.id,
                        "group_name": data.group_name,
                        "description": data.description,
                        "group_status": data.status,
                      })
                      setOpen(true)
                    }
                  },
                  {
                    hint: "ลบ",
                    icon: "trash",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data
                      setOptionAlert({
                        ...optionAlert,
                        action: {
                          id: data.id,
                          name: 'delete'
                        },
                        type: "confirm",
                        title: "ยืนยัน",
                        show: true,
                        description: {
                          visible: true,
                          type: "text",
                          content: "คุณแน่ใจที่จะลบข้อมูล ?",
                          contentLists: []
                        }
                      })
                    }
                  }
                ]} />
              <Toolbar>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => getUserGroup()}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <ExportExcel
                    filename="ข้อมูลกลุ่มผู้ใช้งาน"
                    data={dataSource}
                    columnConfig={[
                      { label: 'ลำดับ', value: 'no' },
                      { label: 'กลุ่มผู้ใช้งาน', value: 'group_name' },
                      { label: 'รายละเอียด', value: 'description' },
                      { label: 'สถานะ', value: 'status_name' },
                      { label: 'แก้ไขโดย', value: 'updated_by' }
                    ]}
                  />
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>

        <Modal
          title={{ text: `${dataForm.id === "" ? 'เพิ่ม' : 'แก้ไข'}กลุ่มผู้ใช้งาน` }}
          open={open}
          maskClosable={true}
          loading={loading}
          onClosed={() => {
            setOpen(false)
            initialData()
          }}
          onOk={() => onSave()}
        >
          <div className="flex flex-col space-y-2">
            <div className='flex-1'>
              <Input
                id="group_name"
                label="กลุ่มผู้ใช้งาน"
                placeholder="กลุ่มผู้ใช้งาน"
                required={true}
                defaultValue={dataForm.group_name}
                validate={validation?.group_name}
                onRef={(ref) => inputRef.current[0] = ref}
              />
            </div>

            <div className='flex-1'>
              <Input
                id="description"
                label="รายละเอียด"
                placeholder="รายละเอียด"
                defaultValue={dataForm.description}
                validate={validation?.description}
                onRef={(ref) => inputRef.current[1] = ref}
              />
            </div>

            <div className='flex-1'>
              <Select
                label="สถานะ"
                placeholder="สถานะ"
                onChange={(select) => setDataForm({ ...dataForm, group_status: select })}
                defaultValue={[dataForm.group_status]}
                options={optionStatus}
              />
            </div>
          </div>
        </Modal>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (["create", "update"].includes(action.name)) {
            setLoading(true)
            saveData()
          }
          else if (action.name === "delete") {
            deleteUserGroup(action.id)
          }

          if (type !== "danger") getUserGroup()
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </>
  )
}))

export default UserGroup