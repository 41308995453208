import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import { FormLoading, Input, ModalAlert, ExportExcel } from '../../../components'
import DataGrid, { SearchPanel, Toolbar, Item, Paging, Pager, Column, HeaderFilter } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import { get, isEmpty } from 'lodash'
import { BASE_URL } from '../../../AppConfig'
import axios from 'axios'
import './style.css'

export interface IColumn {
  dataField: string;
  caption: string;
  allowFiltering?: boolean;
}

let firstRender = false
const FormMaster = inject("masterStore", "authenStore")(observer((props) => {
  let masterStore = props.masterStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }
  let { setMasterStore, isRefreshData } = masterStore
  let {
    tableConfig,
    tableHeaderCustom,
    modalVisible,
    onEdit
  } = props
  let { key, apiPath, columns, exportExcel } = tableConfig

  const [dataSource, setDataSource]: any = useState([])
  const [loading, setLoading] = useState(false)
  const gridRef: any = useRef(null);
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      loadData()
    }
    else
      firstRender = true
  }, [getAccountAuth])

  useEffect(() => isRefreshData && loadData(), [isRefreshData])

  const loadData = async () => {
    setLoading(true)
    let param = ""
    if (tableConfig.screenName === "maintenance") {
      if (tableConfig.vehicleId !== "") {
        param = `?vehicle_id=${tableConfig.vehicleId}`
      }
    }
    await axios.get(`${BASE_URL}/${apiPath}${param}`, {
      headers: auth
    })
      .then((response) => {
        if (response.status === 200) {
          let { result } = response?.data
          setDataSource(result)
        }
        else {
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setDataSource([])
      })

    setMasterStore({ isRefreshData: false })
  }

  const deleteData = async (id) => {
    setLoading(true)

    if (tableConfig?.allowDelete?.paramType === "param") {
      await axios.delete(`${BASE_URL}/${tableConfig?.allowDelete.apiPath}?${[tableConfig?.allowDelete.key]}=${id}`, {
        headers: auth
      })
        .then((response: any) => {
          loadData()
          if (response?.data?.code == 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ลบข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${response?.data?.message}` }
            })
          }
        })
        .catch((error) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${error}` }
          })
        })
    }
    else {
      await axios.delete(`${BASE_URL}/${tableConfig?.allowDelete.apiPath}`, {
        data: { [tableConfig?.allowDelete.key]: id },
        headers: auth
      })
        .then((response: any) => {
          loadData()
          if (response?.data?.code == 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ลบข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${response?.data?.message}` }
            })
          }
        })
        .catch((error) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${error}` }
          })
        })
    }
  }

  return <>
    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action }: any = optionAlert
        if (action.name === "delete") {
          deleteData(action.id)
        }
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
    <FormLoading loading={loading}>
      {tableHeaderCustom}
      <DataGrid
        ref={(ref) => gridRef.current = ref}
        dataSource={dataSource}
        keyExpr={key}
        showBorders={true}
        wordWrapEnabled={false}
        hoverStateEnabled={true}
      >
        <SearchPanel visible={true} />
        <Paging defaultPageSize={10} />
        <Pager
          showInfo={true}
          showPageSizeSelector={true}
          allowedPageSizes={[10, 25, 50]}
        />
        <HeaderFilter visible={true} />
        {
          columns.map((column) => <Column
            key={column.dataField}
            width={column?.width}
            alignment={column?.alignment}
            allowFiltering={column.allowFiltering || false}
            allowSorting={get(column, 'allowSorting', true)}
            dataField={column.dataField}
            caption={column.caption}
            cellRender={column?.cellRender}

          />)
        }
        <Column
          fixed={true}
          caption={`แก้ไข${tableConfig?.allowDelete?.visible ? "/ลบ" : ""}`}
          type="buttons"
          width={80}
          buttons={[
            {
              hint: "แก้ไข",
              icon: "fa fa-pencil-square-o",
              visible: true,
              onClick: (e) => {
                e.cancel = "true"
                let data = e.row.data

                if (modalVisible) {
                  setMasterStore({
                    modalConfig: {
                      show: true,
                      action: "edit",
                      id: data[tableConfig.key],
                      isCustom: tableConfig.isCustom,
                      tableName: tableConfig.tableName,
                      dataSelectRow: data
                    }
                  })
                }
                else {
                  onEdit({
                    modalConfig: {
                      show: true,
                      action: "edit",
                      id: data[tableConfig.key],
                      isCustom: tableConfig.isCustom,
                      tableName: tableConfig.tableName,
                      dataSelectRow: data
                    }
                  })
                }

              }
            },
            {
              hint: "ลบ",
              icon: "trash",
              visible: tableConfig?.allowDelete?.visible ? true : false,
              onClick: (e) => {
                e.cancel = "true"
                let data = e.row.data
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: data[tableConfig?.key],
                    name: 'delete'
                  },
                  type: "confirm",
                  title: "ยืนยัน",
                  show: true,
                  description: {
                    visible: true,
                    type: "text",
                    content: "คุณแน่ใจที่จะลบข้อมูล ?",
                    contentLists: []
                  }
                })
              }
            }
          ]} />
        <Toolbar visible={tableConfig?.hiddenToolbar ? false : true}>
          <Item location="after">
            <button
              type="button"
              className="-mb-1 mt-1 inline-flex items-center px-1 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              title="Refresh"
              onClick={() => loadData()}
            >
              <RefreshIcon className="h-5 w-5" />
            </button>
          </Item>

          <Item location="after">
            <ExportExcel
              filename={exportExcel?.fileName}
              data={dataSource}
              columnConfig={exportExcel?.columnConfig}
            />
          </Item>

          <Item location="after">
            <Input
              icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
              placeholder="ค้นหา..."
              onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
            />
          </Item>
        </Toolbar>
      </DataGrid>
    </FormLoading>
  </>
}))

export default FormMaster